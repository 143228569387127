<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 09:39:19
 * @ Description: 营销管理>标签分组>用户分组>电站分组列表 
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button type="plain" v-rbac="'group:station:add'" @click="add">新建分组</ykc-button>
            <ykc-button type="plain" v-rbac="'group:station:batch'" @click="BatchOperation">
              批量操作
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <ImportData
          ref="addOrEdit"
          :id="id"
          :type="type"
          v-if="drawerType === 'import'"></ImportData>
        <AddOrEdit
          v-if="drawerType === 'edit'"
          :active="stepsIndex"
          ref="addOrEdit"
          :isEdit="isEdit"
          :id="id"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  import ImportData from './ImportData.vue';
  import { stationLabelApi } from '@/service/apis';
  import { code, offlineExport } from '@/utils';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
      ImportData,
    },
    data() {
      return {
        id: '',
        type: 'in',
        cityList: [],
        dictionary: {},
        drawerType: '',
        willEndCount: 0,
        listType: '0',
        stepsIndex: 0,
        maxIndex: 2,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.stepsIndex = 0;
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        statuslist: [
          { id: '0', name: '已启用' },
          { id: '1', name: '已停用' },
        ],
        tableData: [],
        tableColumns: [
          { label: '分组名称', prop: 'groupName', minWidth: '150px' },
          { label: '分组状态', prop: 'labelStatusMean', minWidth: '100px' },
          { label: '电站数', prop: 'stationCount', minWidth: '100px' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '100px' },
        ],
        tableTitle: '电站分组列表',
        tableOperateButtons: [
          {
            enabled: () => code('group:station:stop'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.labelStatusMean !== '启用'}
                onClick={() => {
                  YkcDialog({
                    dialogType: 'feedback',
                    showTitle: false,
                    showFooter: true,
                    desc: `是否确认停用电站分组"${row.groupName}"？`,
                    onCancel: dialogDone => {
                      dialogDone(); // 隐藏弹窗
                    },
                    onConfirm: dialogDone => {
                      dialogDone(); // 隐藏弹窗
                      this.cancelItem(row);
                      this.showDrawer = false; // 隐藏抽屉
                    },
                  });
                }}>
                停用
              </ykc-button>
            ),
          },
          {
            enabled: () => code('group:station:in'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.groupDimension !== '1'}
                onClick={() => {
                  this.type = 'in';
                  this.isEdit = true;
                  this.id = row.id;
                  this.drawerType = 'import';
                  this.showDrawer = true;
                }}>
                迁入
              </ykc-button>
            ),
          },
          {
            enabled: () => code('group:station:out'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.type = 'out';
                  this.isEdit = true;
                  this.id = row.id;
                  this.drawerType = 'import';
                  this.showDrawer = true;
                }}>
                迁出
              </ykc-button>
            ),
          },
          // {
          //   id: '1',
          //   text: '编辑',
          //   handleClick: (btn, { row }) => {
          //     this.isEdit = true;
          //     this.id = row.id;
          //     this.drawerType = 'edit';
          //     this.showDrawer = true;
          //   },
          // },
          {
            enabled: () => code('group:station:detail'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.detailLink(row);
                }}>
                详情
              </ykc-button>
            ),
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        searchParams: {
          groupName: '',
          labelStatus: '',
          groupDimensionL: '',
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      this.$store
        .dispatch('getDictionaryByKeys', [
          'DISCOUNT_TYPE',
          'FLEET_TYPE',
          'DATA_STATUS',
          'OPERATOR_ATTRIBUTES',
          'ENABLE_STATE',
          'PRICE_STATUS',
        ])
        .then(res => {
          this.dictionary = res;
        });
      this.searchTableList();
    },
    computed: {
      drawerTitle() {
        if (this.drawerType === 'import') {
          if (this.type === 'in' || this.type === 'allIn') {
            return '批量迁入';
          }
          if (this.type === 'out') {
            return '批量迁出';
          }
          if (this.type === 'allIn') {
            return '电站分组批量处理';
          }
        }
        return `${this.isEdit ? '编辑' : '新增'}`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'groupName',
            label: '分组名称',
            placeholder: '请输入分组名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'labelStatus',
            label: '分组状态',
            data: this.statuslist,
          },
        ];
      },
    },
    methods: {
      /** 批量操作 */
      BatchOperation() {
        this.type = 'allIn';
        this.isEdit = true;
        this.drawerType = 'import';
        this.showDrawer = true;
      },
      cancelItem(row) {
        stationLabelApi.cancel({ id: row.id }).then(res => {
          console.log(res);
          this.$message({
            message: '保存成功',
            type: 'success',
          });
          this.searchTableList();
        });
      },
      detailLink(row) {
        this.$router.push({
          path: '/marketingCenter/labelStations/Detail',
          query: {
            id: row.id,
          },
        });
      },
      add() {
        this.showAddDrawer = true;
        this.isEdit = false;
        this.id = '';
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      handleSpecialCountClick(row) {
        const routeUrl = this.$router.resolve({
          path: '/priceConfig/specialPurchasePrice',
          query: {
            stationId: row.stationId,
          },
        });
        window.open(routeUrl.href, '_blank');
      },
      changeStateSearch() {
        this.pageInfo.current = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          stationLabelApi.queryForPage({
            ...this.pageInfo,
            ...this.searchParams,
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'group_station_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /** 批量导入文件 */
      doImportFile() {
        this.drawerType = 'import';
        this.isEdit = true;
        this.showDrawer = true;
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);

        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
