<template>
  <!-- 表单样式  如果有温馨提示才加  with-warm-tip， 没有则不加 -->
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <div style="padding-top: 20px">
      <div v-if="active === 0">
        <ykc-form-item label="分组名称" prop="groupName">
          <ykc-input
            v-model="ruleForm.groupName"
            maxlength="50"
            :placeholder="'请输入分组名称'"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="电站范围" prop="groupDimension">
          <ykc-radio :data="dictionary.stationDimen" v-model="ruleForm.groupDimension"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="选择电站" prop="stationList" v-if="ruleForm.groupDimension == '0'">
          <ykc-tree
            v-model="ruleForm.stationList"
            multiple
            filterable
            ref="operaTree"
            :multiple-limit="50"
            placeholder="请选择电站"
            :data="powerStationList"
            :props="treeProps"
            :nodeKey="treeNodeKey"
            @check-change="handleTreeCheckChange"
            style="width: 100%"></ykc-tree>
        </ykc-form-item>
        <ykc-form-item
          class="file-error"
          label="导入电站"
          prop="labelUpload"
          v-if="ruleForm.groupDimension == '1'">
          <ykc-upload
            type="xlsx"
            :size="5"
            ref="labelUpload"
            :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
            @handleRemove="handleRemove"
            @handleChange="labelUpload"
            temText="电站导入模板.xlsx"
            :temhref="temhref"></ykc-upload>
        </ykc-form-item>
      </div>
    </div>
  </ykc-form>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { sessionGetItem } from '@/utils';
  import { getListNameById } from '@/utils/index';
  import { stationLabelApi, treeUtils } from '@/service/apis';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    components: {},
    data() {
      return {
        temhref: `${process.env.VUE_APP_OSS}/resource/标签电站导入.xlsx`,
        treeProps: {
          label: 'stationName',
          children: 'stationTreeVOList',
          disabled: 'disabled',
        },
        powerStationList: [],
        treeNodeKey: 'stationId',
        dictionary: {
          stationDimen: [
            { id: '0', name: '按电站' },
            { id: '1', name: '手动导入' },
          ],
        },
        ruleForm: {
          stationDimen: '0',
          groupName: '',
          groupDimension: '0',
          stationList: [],
          list: [],
        },
        rules: {
          groupDimension: [{ required: true, message: '请选择电站范围', trigger: 'blur' }],
          groupName: [
            { required: true, message: '请输入分组名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('分组名称'),
            },
          ],
          stationList: [{ required: true, message: '请选择电站', trigger: 'change' }],
          labelUpload: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.labelUpload.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要导入的文件'));
                }
              },
            },
          ],
        },
      };
    },
    watch: {},
    computed: {
      formData() {
        const formData = {
          operatorId: sessionGetItem('accountId'),
          id: this.ruleForm.id,
          groupName: this.ruleForm.groupName,
          groupDimension: this.ruleForm.groupDimension,
          stationList: this.ruleForm.stationList,
          list: this.ruleForm.stationList,
        };
        return formData;
      },
    },
    created() {
      // this.$store
      //   .dispatch('getDictionaryByKeys', [
      //     'DISCOUNT_TYPE',
      //     'PREFERENTIAL_TYPE',
      //     'CONFIG_TYPE',
      //     'STATION_RANGE',
      //     'ENABLE_STATE',
      //   ])
      //   .then(res => {
      //     this.dictionary = res;
      //   });
      this.getStationTree();
      if (this.isEdit) {
        stationLabelApi
          .detail({
            list: this.id,
          })
          .then(res => {
            this.ruleForm = {
              ...res,
            };
          });
      }
    },
    methods: {
      getListNameById,
      getStationTree() {
        treeUtils.operatorStations({}).then(res => {
          this.powerStationList = res;
          this.treeProps = {
            label: 'stationName',
            children: 'stationTreeVOList',
            disabled: 'disabled',
          };
          this.treeNodeKey = 'stationId';
          const listData = res || [];
          const kayMap = { operatorName: 'stationName', operatorId: 'stationId' };
          const setEmpty = (data, keyMap) => {
            const objs = Object.keys(data).reduce((newData, key) => {
              const newKey = keyMap[key] || key;
              newData[newKey] = data[key];
              return newData;
            }, {});
            return objs;
          };
          this.powerStationList = listData.map(item => {
            return setEmpty(item, kayMap);
          });
        });
      },
      handleTreeCheckChange() {
        this.ruleForm.stationList = this.$refs.operaTree.getCheckedKeys(true);
      },
      labelUpload(file, fileList, isCheck) {
        // console.log(file, fileList, isCheck);
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
          };
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          stationLabelApi.uploadFile(formData).then(res => {
            console.log(JSON.stringify(res));
            this.ruleForm.stationList = res.stationList;
          });
        }
      },
      handleRemove() {
        this.ruleForm.file = undefined;
      },
      submitForm() {
        if (this.ruleForm.stationList.length === 0) {
          this.$message({
            message: '数据为空不允许提交',
            type: 'success',
          });
          return null;
        }
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (this.isEdit) {
              stationLabelApi.uploadFile(this.formData).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            } else {
              stationLabelApi.add(this.formData).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
