<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item class="file-error" label="选择文件" prop="file">
      <ykc-upload
        type="xlsx"
        :size="5"
        ref="file"
        :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
        @handleRemove="handleRemove"
        @handleChange="fileUpload"
        :temText="linkText"
        :temhref="modelUrl"></ykc-upload>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { filterByIdList } from '../../../utils/index';
  import { stationLabelApi } from '../../../service/apis';

  export default {
    props: {
      type: {
        type: String,
        default: 'in',
      },
      id: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        dictionary: {},
        rules: {
          file: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.file.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要导入的文件'));
                }
              },
            },
          ],
        },
        ruleForm: {
          file: undefined,
        },
        formData: null,
      };
    },
    computed: {
      modelUrl() {
        if (this.type === 'in') {
          return `${process.env.VUE_APP_OSS}/marketing/uploadFile/%E7%94%B5%E7%AB%99%E8%BF%81%E5%85%A5.xls`;
        }
        if (this.type === 'allIn') {
          return `${process.env.VUE_APP_OSS}/marketing/uploadFile/%E7%94%B5%E7%AB%99%E6%89%B9%E9%87%8F%E8%BF%81%E5%85%A5%E8%BF%81%E5%87%BA.xls`;
        }
        if (this.type === 'out') {
          return `${process.env.VUE_APP_OSS}/marketing/uploadFile/%E7%94%B5%E7%AB%99%E8%BF%81%E5%87%BA.xls`;
        }
        return ``;
      },
      linkText() {
        if (this.type === 'in') {
          return '电站迁入模板.xlsx';
        }
        if (this.type === 'allIn') {
          return '批量处理模板.xlsx';
        }
        return '电站迁出模板.xlsx';
      },
    },
    created() {
      // 获取数据字典的数据
      this.$store.dispatch('getDictionaryByKeys', ['DISCOUNT_TYPE']).then(res => {
        this.dictionary = res;
      });
    },
    methods: {
      filterByIdList,
      fileUpload(file, fileList, isCheck) {
        console.log(file);
        console.log(fileList);
        console.log(isCheck);
        if (isCheck) {
          this.formData = new FormData();
          const param = {
            file: file.raw,
          };
          Object.entries(param).forEach(([k, v]) => {
            this.formData.append(k, v);
          });
          if (this.type === 'in' || this.type === 'out') {
            stationLabelApi.uploadFile(this.formData).then(res => {
              console.log(JSON.stringify(res));
              this.ruleForm.stationList = res.stationList;
            });
            this.ruleForm.id = this.id;
            this.ruleForm.type = this.type;
          }
        }
        this.$refs.ruleForm.validateField('file');
      },
      handleRemove() {
        this.ruleForm.file = undefined;
      },
      submitForm() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (this.type === 'in' || this.type === 'out') {
              stationLabelApi.edit(this.ruleForm).then(() => {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                });
                resolve();
              });
            } else {
              stationLabelApi.batch(this.formData).then(() => {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise(resolve => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
